<template>
	<b-modal id="share-details-modal" 
		hide-footer  
		hide-header
		no-close-on-backdrop 
		size="md" 
		modal-class="share-details-modal"
		body-bg-variant="dark"
	>


		<div> 
			<div class="message-border mb-3">
				<div class="message text-white text-center">
					<h6>You are about to share your data information to others. 
						Please select which data assets you would like to share: **Your FACE and FULLNAME are always required to share.**
					</h6>
				</div>
			</div>
			<h3>List of data assets:</h3>
			<form class="mt-4 text-white">

				<div class="form-check" v-for="(assets, index) in staticDataAssets">
					<input class="form-check-input" type="checkbox" v-model="dataAssets[assets.index]" :disabled="!allowShow[assets.index]" :id="'key' + index" @change="show">
					<label class="form-check-label" :for="'key' + index">
						{{ assets.name }}
					</label>
				</div>
				<!-- <div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.SELFIE" :disabled="!allowShow.SELFIE" id="SELFIE" @change="show">
					<label class="form-check-label" for="SELFIE">
						Face
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.HOMENUMBER" :disabled="!allowShow.HOMENUMBER" id="HOMENUMBER" @change="show">
					<label class="form-check-label" for="HOMENUMBER">
						Home Number
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.DRIVERSLICENSE" :disabled="!allowShow.DRIVERSLICENSE" id="DRIVERSLICENSE" @change="show">
					<label class="form-check-label" for="DRIVERSLICENSE">
						Driver’s License
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.PASSPORT" :disabled="!allowShow.PASSPORT" id="PASSPORT" @change="show">
					<label class="form-check-label" for="PASSPORT">
						Passport
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.POA" :disabled="!allowShow.POA" id="POA" @change="show">
					<label class="form-check-label" for="POA">
						Proof of Address
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.EMPLOYMENT" :disabled="!allowShow.EMPLOYMENT" id="EMPLOYMENT" @change="show">
					<label class="form-check-label" for="EMPLOYMENT">
						Employment
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.HOMEASSET" :disabled="!allowShow.HOMEASSET" id="HOMEASSET" @change="show">
					<label class="form-check-label" for="HOMEASSET">
						Home Asset
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.VEHICLEASSET" :disabled="!allowShow.VEHICLEASSET" id="VEHICLEASSET" @change="show">
					<label class="form-check-label" for="VEHICLEASSET">
						Vehicle Asset
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.EDUCATION" :disabled="!allowShow.EDUCATION" id="EDUCATION" @change="show">
					<label class="form-check-label" for="EDUCATION">
						Education
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.GENDER" :disabled="!allowShow.GENDER" id="GENDER" @change="show">
					<label class="form-check-label" for="GENDER">
						Gender
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="dataAssets.OTHER" :disabled="!allowShow.OTHER" id="OTHER" @change="show">
					<label class="form-check-label" for="OTHER">
						Other
					</label>
				</div> -->
			</form>
			<div class="message-border mt-5">
				<div class="message text-white text-center">
					<h6>I hereby understand that I am sharing these information 
					via this link to get verification from IDChain community.</h6>
				</div>
			</div>
			<div class="d-flex flex-wrap justify-content-between mt-5" style="width:100%;">
				<button class="btn btn-main" style="width:40%;" @click="generateKey" :disabled="!showButton">Share</button>
				<button class="btn btn-cancel" style="width:40%;" @click="cancel">Cancel</button>
			</div>
			<p style="color:gray; font-size: 7px; margin-left:10px;">version 1.001</p>
		</div>
		<ChangePin/>
        <ConfirmLogout />
        <GenerateKey :sharingKey="key"/>
	</b-modal>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import GenerateKey from '@/components/modal/GenerateKey.vue'

export default {
	components: {
        ChangePin,
        ConfirmLogout,
        GenerateKey
    },
	data() {
		return {
			allowShow: {
				SELFIE: true,
                HOMENUMBER: false,
                DRIVERSLICENSE: false,
                PASSPORTNUMBER: false,
                POA: false,
                HOMEASSET: false,
                VEHICLEASSET: false,
                OTHER: false,
                GENDER: false, 
                NBICLEARANCE: false, 
                EDUCATION: false, 
                EMPLOYMENTCERT: false, 
                VOTERSID: false, 
				FULLNAME: true
			},

			dataAssets: {
                SELFIE: true,
                HOMENUMBER: false,
                DRIVERSLICENSE: false,
                PASSPORTNUMBER: false,
                POA: false,
                HOMEASSET: false,
                VEHICLEASSET: false,
                OTHER: false,
                GENDER: false, 
                NBICLEARANCE: false, 
                EDUCATION: false, 
                EMPLOYMENTCERT: false, 
                VOTERSID: false, 
				FULLNAME: true
            },
            staticDataAssets: {},
            key: "",
            showButton: false
		}
	},
	computed: {
      init() {
          return this.$store.state.init;
      },
    },
	methods: {
        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        show() {
            this.showButton = true
        },
		cancel() {
			this.$bvModal.hide('share-details-modal')
		},
		getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    // this.poids = response.data
					console.log('.')
					Object.keys(this.allowShow).forEach((key) => {
						//console.log(key)
						response.data.forEach((value) => {
							
							//console.log(key + " - " + value.poidType + ' - ' + "NBICLEARANCE1" )
							
							if(value.poidType == key) {
								//this.allowShow[key] = true

								this.$set(this.allowShow, key, true);
							}
						})
					})

                })
                .catch((error) => {
                    
                });
        },


        getProfile() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/profile',
            };
            this.axios(options)
                .then((response) => {
                 
 
                   if(response.data.emailAddress == ''
                   		|| response.data.emailAddress == null) { 

                   	this.$set(this.allowShow, 'EMAIL', false); 
                   } else {
                   	this.$set(this.allowShow, 'EMAIL', true); 
                   }

                   if(response.data.birthDate == ''
                   		|| response.data.birthDate == null) { 

                   	this.$set(this.allowShow, 'BDAY', false); 
                   } else {
                   	this.$set(this.allowShow, 'BDAY', true); 
                   }

                   if(response.data.mobilePhone == ''
                   		|| response.data.mobilePhone == null) { 

                   	this.$set(this.allowShow, 'MOBILE', false); 
                   } else {
                   	this.$set(this.allowShow, 'MOBILE', true); 
                   }

                   if(response.data.gender == ''
                   		|| response.data.gender == null) { 

                   	this.$set(this.allowShow, 'GENDER', false); 
                   } else {
                   	this.$set(this.allowShow, 'GENDER', true); 
                   }

                   if(response.data.citizenship == ''
                   		|| response.data.citizenship == null) { 

                   	this.$set(this.allowShow, 'CITIZENSHIP', false); 
                   } else {
                   	this.$set(this.allowShow, 'CITIZENSHIP', true); 
                   }

                   if(response.data.citizenship == ''
                   		|| response.data.citizenship == null) { 

                   	this.$set(this.maritalStatus, 'CIVILSTATUS', false); 
                   } else {
                   	this.$set(this.maritalStatus, 'CIVILSTATUS', true); 
                   }

                   if(response.data.fullName == ''
                   		|| response.data.fullName == null) { 

                   	this.$set(this.maritalStatus, 'FULLNAME', false); 
                   } else {
                   	this.$set(this.maritalStatus, 'FULLNAME', true); 
                   }


                })
                .catch((error) => {
                    
                });
        },
        generateKey() {
            var dataString = ""

            for (var key in this.dataAssets) {
                if (this.dataAssets.hasOwnProperty(key)) {
                    if(this.dataAssets[key] == true) {
                        dataString = dataString + key + ", "
                    }
                }
            }

            console.log(dataString.substring(0, dataString.length - 2))
            const params = {
                data: dataString.substring(0, dataString.length - 2)
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');

            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/generatekey?' + data,
            };
            this.axios(options)
                .then((response) => {
                   this.key = window.location.href + 'data-assets/' + response.data.tokenId
                   console.log(this.key)
                   this.$bvModal.show('generate-key-modal'); 
                })
                .catch((error) => {
                    
                });
        },
    },
    mounted() {
		this.getProfile();
		this.getPoids();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
    // this.allowShow[key] = true;

          //             { index: 'EMAIL', name: "Email Address" },
          // { index: 'BDAY', name: "Birthdate" },
          // { index: 'MOBILE', name: "Mobile Number" },
          // { index: 'GENDER', name: "Gender" },
          // { index: 'CITIZENDSHIP', name: "Citizenship" },
          // { index: 'PASSPORTNUMBER', name: "Govt Valid ID" },
          // { index: 'HOMEASSET', name: "Home Asset" },
          // { index: 'EDUCATION', name: "Educational Certificate" },
          // { index: 'EMPLOYMENTCERT', name: "Employment Certificate" },
          // { index: 'VEHICLEASSET', name: "Vehicle Asset" },
          // { index: 'NBICLEARANCE', name: "NBI Clearance" },
          // { index: 'VOTERSID', name: "Voter's ID" },
          // { index: 'CIVILSTATUS', name: "Civil Status" },
          // { index: 'FULLNAME', name: "Full name" },
          // { index: 'SELFIE', name: "Face" }


        this.staticDataAssets = [
          { index: 'EMAIL', name: "Email Address" },
          { index: 'BDAY', name: "Birthdate" },
          { index: 'MOBILE', name: "Mobile Number" },
          { index: 'GENDER', name: "Gender" },
          { index: 'CITIZENSHIP', name: "Citizenship" },
          { index: 'PASSPORTNUMBER', name: "Primary ID" },
          { index: 'HOMEASSET', name: "Home Asset" },
		  { index: 'POA', name: "Proof of Address" },
          { index: 'EDUCATION', name: "Educational Certificate" },
          { index: 'EMPLOYMENTCERT', name: "Employment Certificate" },
          { index: 'VEHICLEASSET', name: "Vehicle Asset" },
          { index: 'NBICLEARANCE', name: "NBI Clearance" },
          { index: 'VOTERSID', name: "Voter's ID" },
          { index: 'CIVILSTATUS', name: "Civil Status" },
          { index: 'FULLNAME', name: "Full name" },
          { index: 'SELFIE', name: "Face" },
        ];
    }
}
</script>

<style scoped>
	.message-border {
		width:90%;
		margin:0 auto;
		background:linear-gradient(to right, #0e649e, #fff700, #9e0e0e);
		border-radius:5px;
		padding:2px;
	}

	.message {
		background:#062035;
		padding:15px;
		border-radius:5px;
	}

	.btn-cancel {
		border:2px #9e0e0e solid;
		color:white;
		border-radius:5px;
	}

	h3 {
		color:#3297db;
	}
</style>